import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import TestUI from "./pages/TestUI";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path="test" element={<TestUI />} />
        </Route>
      </Routes>    
    </BrowserRouter>
  );
}

const root = createRoot(document.getElementById("root"));
root.render(<App />);