export function ActivityScore(left = null, right = null, bilateral = null, unit = '') {
    return { left, right, bilateral, unit };
}

export function MessageBody(currentActivity, event, score = null, secondsRemaining) {
    return { currentActivity, event, score, secondsRemaining };
}

let lastActivityMessage = "";

export function sendActivityMessage(type, body) {
    const messageString = JSON.stringify(body);
    if (lastActivityMessage === messageString) {
        return;
    }
    lastActivityMessage = messageString;

    // const activityMessage = document.getElementById('activity');
    // if (activityMessage) { activityMessage.innerHTML = messageString; }

    parent.postMessage({ msgType: type, msgBody: body }, "*");
}
