/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import * as posedetection from '@tensorflow-models/pose-detection';

export const DEFAULT_LINE_WIDTH = 1.5;
export const DEFAULT_RADIUS = 2.5;

export const VIDEO_SIZE = {
  '640 X 480': {width: 640, height: 480},
  '480 X 640': {width: 480, height: 640},
  '640 X 360': {width: 640, height: 360},
  '360 X 270': {width: 360, height: 270},
  '360 X 480': {width: 360, height: 480},
};

export const MOBILE_VIDEO_SIZE = {
  width: 600,
  height: 415,
}

export const COMPONENT_SIZE = {
  width: 480,
  height: 640,
}

export const STATE = {
  camera: {targetFPS: 60, sizeOption: '360 X 480'},
  backend: '',
  flags: {},
  modelConfig: {},
};
export const BLAZEPOSE_CONFIG = {
  maxPoses: 1,
  type: 'full',
  scoreThreshold: 0.05,
  render3D: false,
};
export const POSENET_CONFIG = {
  maxPoses: 1,
  scoreThreshold: 0.5,
};
export const MOVENET_CONFIG = {
  maxPoses: 1,
  type: 'lightning',
  scoreThreshold: 0.05,
  customModel: '',
  enableTracking: false,
};
/**
 * This map descripes tunable flags and theior corresponding types.
 *
 * The flags (keys) in the map satisfy the following two conditions:
 * - Is tunable. For example, `IS_BROWSER` and `IS_CHROME` is not tunable,
 * because they are fixed when running the scripts.
 * - Does not depend on other flags when registering in `ENV.registerFlag()`.
 * This rule aims to make the list streamlined, and, since there are
 * dependencies between flags, only modifying an independent flag without
 * modifying its dependents may cause inconsistency.
 * (`WEBGL_RENDER_FLOAT32_CAPABLE` is an exception, because only exposing
 * `WEBGL_FORCE_F16_TEXTURES` may confuse users.)
 */
export const TUNABLE_FLAG_VALUE_RANGE_MAP = {
  WEBGL_VERSION: [1, 2],
  WASM_HAS_SIMD_SUPPORT: [true, false],
  WASM_HAS_MULTITHREAD_SUPPORT: [true, false],
  WEBGL_CPU_FORWARD: [true, false],
  WEBGL_PACK: [true, false],
  WEBGL_FORCE_F16_TEXTURES: [true, false],
  WEBGL_RENDER_FLOAT32_CAPABLE: [true, false],
  WEBGL_FLUSH_THRESHOLD: [-1, 0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
  CHECK_COMPUTATION_FOR_ERRORS: [true, false],
};

export const BACKEND_FLAGS_MAP = {
  ['tfjs-wasm']: ['WASM_HAS_SIMD_SUPPORT', 'WASM_HAS_MULTITHREAD_SUPPORT'],
  ['tfjs-webgl']: [
    'WEBGL_VERSION', 'WEBGL_CPU_FORWARD', 'WEBGL_PACK',
    'WEBGL_FORCE_F16_TEXTURES', 'WEBGL_RENDER_FLOAT32_CAPABLE',
    'WEBGL_FLUSH_THRESHOLD'
  ],
  ['mediapipe-gpu']: [],
};

export const MODEL_BACKEND_MAP = {
  [posedetection.SupportedModels.PoseNet]: ['tfjs-webgl'],
  [posedetection.SupportedModels.MoveNet]: ['tfjs-webgl', 'tfjs-wasm'],
  [posedetection.SupportedModels.BlazePose]: ['mediapipe-gpu', 'tfjs-webgl'],
};

export const TUNABLE_FLAG_NAME_MAP = {
  PROD: 'production mode',
  WEBGL_VERSION: 'webgl version',
  WASM_HAS_SIMD_SUPPORT: 'wasm SIMD',
  WASM_HAS_MULTITHREAD_SUPPORT: 'wasm multithread',
  WEBGL_CPU_FORWARD: 'cpu forward',
  WEBGL_PACK: 'webgl pack',
  WEBGL_FORCE_F16_TEXTURES: 'enforce float16',
  WEBGL_RENDER_FLOAT32_CAPABLE: 'enable float32',
  WEBGL_FLUSH_THRESHOLD: 'GL flush wait time(ms)',
};

export const KEYPOINTS = {
    NOSE: 0,
    LEFT_EAR: 7,
    RIGHT_EAR: 8,
    LEFT_MOUTH: 9,
    RIGHT_MOUTH: 10,
    LEFT_SHOULDER: 11,
    RIGHT_SHOULDER: 12,
    LEFT_ELBOW: 13,
    RIGHT_ELBOW: 14,
    LEFT_WRIST: 15,
    RIGHT_WRIST: 16,
    CHEST: 17,
    LEFT_HIP: 23,
    RIGHT_HIP: 24,
    LEFT_KNEE: 25,
    RIGHT_KNEE: 26,
    LEFT_ANKLE: 27,
    RIGHT_ANKLE: 28,
    LEFT_HEEL: 29,
    RIGHT_HEEL: 30,
    LEFT_FOOT_INDEX: 31,
    RIGHT_FOOT_INDEX: 32,
};

export const ASSESSMENT_POSITION = {
  NOT_STARTED: 'not_started',
  FOUR_STEP_BALANCE: 'four_step_balance',
  SIT_TO_STAND: 'sit_to_stand',
  SHOULDER_ABDUCTION: 'shoulder_abduction',
  SHOULDER_FLEXION: 'shoulder_flexion',
  SHOULDER_EXTENSION: 'shoulder_extension',
  ELBOW_FLEXION: 'elbow_flexion',
  CERVICAL_FLEXION: 'cervical_flexion',
  CERVICAL_EXTENSION: 'cervical_extension',
  CERVICAL_LATERAL_FLEXION: 'cervical_lateral_flexion',
  LUMBAR_FLEXION: 'lumbar_flexion',
  LUMBAR_EXTENSION: 'lumbar_extension',
  LUMBAR_LATERAL_FLEXION: 'lumbar_lateral_flexion',
  HIP_FLEXION: 'hip_flexion',
  HIP_EXTENSION: 'hip_extension',
  SQUAT_KNEE_FLEXION: 'squat_knee_flexion',
  CERVICAL_ROTATION: 'cervical_rotation',
  HIP_INTERNAL_ROTATION: 'hip_internal_rotation',
  HIP_EXTERNAL_ROTATION: 'hip_external_rotation',
  SQUAT_HIP_FLEXION: 'squat_hip_flexion',
  COMPLETE: 'assessment_completed',
  END: 'assessment_finish',
};

export const UNITS_SCORE = {
  REPETITIONS: 'REPS',
  SECONDS: 'SECONDS',
  AGGREGATED: 'AGGREGATED',
  DEGREES: 'DEGREES',
};

export const MESSAGE_TYPE = {
  ACTIVITY: 'activityMessage',
};

export const EVENTS = {
  NOT_IN_FRAME: 'not_in_frame',
  IN_FRAME: 'in_frame',
  IN_POSITION: 'in_position',
  BODY_CLOSE: 'body_too_close',
  STARTED: 'started',
  FALL: 'fall',
  ABORTED: 'aborted',
  SHOW_INSTRUCTIONS: 'show_instructions',
  FINISHED: 'finished',
};

// #ffffff - White
// #800000 - Maroon
// #469990 - Malachite
// #e6194b - Crimson
// #42d4f4 - Picton Blue
// #fabed4 - Cupid
// #aaffc3 - Mint Green
// #9a6324 - Kumera
// #000075 - Navy Blue
// #f58231 - Jaffa
// #4363d8 - Royal Blue
// #ffd8b1 - Caramel
// #dcbeff - Mauve
// #808000 - Olive
// #ffe119 - Candlelight
// #911eb4 - Seance
// #bfef45 - Inchworm
// #f032e6 - Razzle Dazzle Rose
// #3cb44b - Chateau Green
// #a9a9a9 - Silver Chalice

export const COLOR_PALETTE = [
  '#ffffff', '#800000', '#469990', '#e6194b', '#42d4f4', '#fabed4', '#aaffc3',
  '#9a6324', '#000075', '#f58231', '#4363d8', '#ffd8b1', '#dcbeff', '#808000',
  '#ffe119', '#911eb4', '#bfef45', '#f032e6', '#3cb44b', '#a9a9a9',
];

export const COLORS = {
  DEFAULT: '#ffffff',
  POINTS: '#3cb8af',
};

// These anchor points allow the pose pointcloud to resize according to its
// position in the input.
export const ANCHOR_POINTS = [[0, 0, 0], [0, 1, 0], [-1, 0, 0], [-1, -1, 0]];
