/* INFO: Points top left is 1000 x and 0 Y. The 0,0 is the top right. Camera is fliped. Btm is > 1000*/

import * as params from '../../../config/params';
import * as mathUtils from '../../../utils/math';
import * as puppet from '../puppet';
import {isMobile} from '../../../utils/device';
import * as messageService from '../../../services/message_service';
import { checkGeneralFall } from '../activity_picker';

const thresholdStartPoint = 0.75;
const valueNotDefined = -1;

let holdStartPositionCount = 0;
let userInStartPositionForTest = false;
let userHasWaitedForStart = false;
let assessmentPosition = params.ASSESSMENT_POSITION.NOT_STARTED;

let timeToHoldFourStage = 10;
let currentActivity = '';
let score = valueNotDefined;
let finishDate;
let heightThreshold = valueNotDefined;
let frameHeight = undefined;
let frameWidth = undefined;
let inFrameWeight = valueNotDefined;

function setActivityMessage(event = '', currentScore = valueNotDefined){

    const secondsRemaining = calculateSecondsRemaining();

    if (currentScore > score) {
        score = currentScore;
    }

    const activityScore = messageService.ActivityScore(null, null, score, params.UNITS_SCORE.SECONDS);
    const msg = messageService.MessageBody(currentActivity, event, activityScore, secondsRemaining);

    messageService.sendActivityMessage(params.MESSAGE_TYPE.ACTIVITY, msg);
}

function calculateSecondsRemaining() {
    if (finishDate == undefined) {
        return null;
    } else {
        const currentDate = new Date();
        const timeDifference = (finishDate.getTime() - currentDate.getTime()) / 1000;
        return Math.ceil(timeDifference);
    }
}

function hasFinishedTimeExpected() {
    const secondsRemaining = calculateSecondsRemaining();
    return (secondsRemaining < 0 && secondsRemaining != null);
}

function poseDetectionFS(keypoints, activityName, activityDuration, source, videoHeight, videoWidth) {
    frameHeight = videoHeight;
    frameWidth = videoWidth;
    if (activityDuration === null || activityDuration == 0) {
        activityDuration = 10;
    }
    currentActivity = activityName;
    timeToHoldFourStage = activityDuration;

    switch (assessmentPosition) {
        case params.ASSESSMENT_POSITION.NOT_STARTED:
            holdStartPositionCount = 0;
            validateUserIsInFrame(keypoints);
            break;
        case params.ASSESSMENT_POSITION.FOUR_STEP_BALANCE:
            fourStageBalance(keypoints, source);
            break;
        case params.ASSESSMENT_POSITION.COMPLETE:
            score = valueNotDefined;
            userInStartPositionForTest = false;
            finishDate = undefined;
            assessmentPosition = params.ASSESSMENT_POSITION.END;
            break;
    }
}

function validateUserIsInFrame(keypoints) {
    const CLOSE_MARGIN_THRESHOLD = frameHeight * 0.03;

    const nose = keypoints[params.KEYPOINTS.NOSE];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const rHand = keypoints[params.KEYPOINTS.RIGHT_WRIST];
    const lHand = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];

    function validateBetweenLimits(keypoint) {
      let upperLimit = CLOSE_MARGIN_THRESHOLD;
      let bottomLimit = (frameHeight - CLOSE_MARGIN_THRESHOLD);
      let leftLimit = frameWidth * 0.05
      let rightLimit = frameWidth - leftLimit;
      let isBetweenY = keypoint.y > upperLimit && keypoint.y < bottomLimit;
      let isBetweenX = keypoint.x > leftLimit && keypoint.x < rightLimit;
      return isBetweenX && isBetweenY;
    }

    const arePointsInFrame = validateBetweenLimits(nose) && validateBetweenLimits(lAnkle) 
        && validateBetweenLimits(rAnkle) && validateBetweenLimits(rHand) 
        && validateBetweenLimits(lHand) && validateBetweenLimits(rHip) 
        && validateBetweenLimits(lHip);

    if (arePointsInFrame) {
        inFrameWeight++;
    } else {
        inFrameWeight = 0;
        setActivityMessage(params.EVENTS.NOT_IN_FRAME);
        assessmentPosition = params.ASSESSMENT_POSITION.NOT_STARTED;
    }

    const inFrameWeightThreshold = isMobile() ? 50 : 80;
    if (inFrameWeight > inFrameWeightThreshold) {
        setActivityMessage(params.EVENTS.IN_FRAME);
        assessmentPosition = params.ASSESSMENT_POSITION.FOUR_STEP_BALANCE;
    }
}

// -----------------------IN POSITION - START
function detectInPositionToStartTest(keypoints) {
    validateUserIsInFrame(keypoints);

    const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint);

    if (isFrontal) {
        holdStartPositionCount++;
    } else {
        holdStartPositionCount = 0;
    }

    const timeToHold = isMobile() ? 60 : 90;
    let assessmentStartCondition = holdStartPositionCount > timeToHold;
    if (assessmentStartCondition) {
        holdStartPositionCount = 0;
        userInStartPositionForTest = true;
        setActivityMessage(params.EVENTS.IN_POSITION);
    }
}

function waitInPosition(keypoints) {
    const timeExpectedUntilStart = 5;

    if (finishDate === undefined) {
        
        const lWristY = keypoints[params.KEYPOINTS.LEFT_WRIST].y;
        const rWristY = keypoints[params.KEYPOINTS.RIGHT_WRIST].y;
        const head = keypoints[params.KEYPOINTS.NOSE];
        const hasAtLeastOneArmRaised = lWristY <= head.y || rWristY <= head.y;
        if (hasAtLeastOneArmRaised) {
            finishDate = new Date();
            finishDate.setSeconds(finishDate.getSeconds() + timeExpectedUntilStart);
        }
    }    

    if (hasFinishedTimeExpected()) {
        heightThreshold = keypoints[params.KEYPOINTS.LEFT_HIP].y;
        finishDate = undefined;
        setActivityMessage(params.EVENTS.STARTED, 0);
        userHasWaitedForStart = true;
    } else {
        setActivityMessage(params.EVENTS.IN_POSITION);
    }
}

// -----------------------FOUR STEP BALANCE - START
let currentStage = 1;
let hasShownInstruction = true;
const maxWeightForFailure = isMobile() ? 50 : 80;
let currentFailureWeight = 0;
let currentTimerScore = 0;

function waitForInstruction() {
    const expectedInstructionTime = 5;
    if (finishDate === undefined) {
        finishDate = new Date();
        finishDate.setSeconds(finishDate.getSeconds() + expectedInstructionTime);
    }
    if (hasFinishedTimeExpected()) {
        finishDate = undefined;
        hasShownInstruction = true;
    }
    setActivityMessage(params.EVENTS.SHOW_INSTRUCTIONS);
}

function fourStageBalance(keypoints, source) {
    if (!hasShownInstruction) {
        waitForInstruction();
        return;
    }

    // Initial check to see if user is in position to start test, video are not included in this check
    if (!userInStartPositionForTest && source !== 'video') {
        detectInPositionToStartTest(keypoints);
        return;
    }

    if (!userHasWaitedForStart) {
        waitInPosition(keypoints);
        return;
    }

    // TODO: once we merge branch 32, this validation goes to camera.
    if (checkGeneralFall(keypoints, heightThreshold)) {
        finishDate = undefined;
        setActivityMessage(params.EVENTS.FALL);
        assessmentPosition = params.ASSESSMENT_POSITION.END;
        return;
    }

    if (currentFailureWeight >= maxWeightForFailure) {
        setActivityMessage(params.EVENTS.ABORTED);
        finishDate = undefined;
        assessmentPosition = params.ASSESSMENT_POSITION.END;
        return;
    }

    if (finishDate !== undefined && currentStage > 0) {
        let secsRemaining = calculateSecondsRemaining();
        if (secsRemaining >= 0){
            currentTimerScore = ((currentStage - 1) * timeToHoldFourStage) + (timeToHoldFourStage - secsRemaining);
    
        }
    }
    
    setActivityMessage(params.EVENTS.STARTED, currentTimerScore);
    switch (currentStage) {
        case 1://Two feet together
            firstPositionCalculation(keypoints);
            break;
        case 2://Instep
            secondPositionCalculation(keypoints);
            break;
        case 3://TandemStand
            thirdPositionCalculation(keypoints);
            break;
        case 4://OneFoot
            fourthPositionCalculation(keypoints);
        break;
    }
}

function firstPositionCalculation(keypoints) {
    const lHeel = keypoints[params.KEYPOINTS.LEFT_HEEL];
    const rHeel = keypoints[params.KEYPOINTS.RIGHT_HEEL];
    const lFootIndex = keypoints[params.KEYPOINTS.LEFT_FOOT_INDEX];
    const rFootIndex = keypoints[params.KEYPOINTS.RIGHT_FOOT_INDEX];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];

    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
    const ankleSameLevelY = mathUtils.arePointsClose(rAnkle.y, lAnkle.y, (uow * 0.5));
    const anklesFootCloseX = mathUtils.arePointsClose(rKnee.x, rFootIndex.x, uow) && mathUtils.arePointsClose(lKnee.x, lFootIndex.x, uow);

    const anklesAreCloseX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, uow);
    const heelAreCloseX = mathUtils.arePointsClose(rHeel.x, lHeel.x, uow);
    const fingersAreCloseX = mathUtils.arePointsClose(rFootIndex.x, lFootIndex.x, uow);
    const footAreCloseX = anklesAreCloseX && heelAreCloseX && fingersAreCloseX;

    let firstPosition = ankleSameLevelY && footAreCloseX && anklesFootCloseX;

    if (firstPosition && finishDate === undefined) {
        finishDate = new Date();
        finishDate.setSeconds(finishDate.getSeconds() + timeToHoldFourStage);
    }

    if (hasFinishedTimeExpected()) {
        currentStage = 2;
        finishDate = undefined;
        hasShownInstruction = false;
        return;
    }

    const failureCondition = (finishDate !== undefined && (!ankleSameLevelY || !anklesAreCloseX || !fingersAreCloseX));
    if (failureCondition) {
        currentFailureWeight++;
    } else {
        currentFailureWeight = 0;
    }
}

function secondPositionCalculation(keypoints) {
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lHeel = keypoints[params.KEYPOINTS.LEFT_HEEL];
    const rHeel = keypoints[params.KEYPOINTS.RIGHT_HEEL];
    const lFootIndex = keypoints[params.KEYPOINTS.LEFT_FOOT_INDEX];
    const rFootIndex = keypoints[params.KEYPOINTS.RIGHT_FOOT_INDEX];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];

    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));

    const ankleSameLevelY = mathUtils.arePointsClose(rAnkle.y, lAnkle.y, (uow * 0.5));
    const anklesFootCloseX = mathUtils.arePointsClose(rKnee.x, rFootIndex.x, uow) && mathUtils.arePointsClose(lKnee.x, lFootIndex.x, uow);
    
    const anklesAreCloseX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.8));
    const heelAreCloseX = mathUtils.arePointsClose(rHeel.x, lHeel.x, uow);
    const fingersAreCloseX = mathUtils.arePointsClose(rFootIndex.x, lFootIndex.x, uow);
    const footAreCloseX = anklesAreCloseX && heelAreCloseX && fingersAreCloseX;

    const footIsAtDistanceY = Math.abs(rFootIndex.y - lFootIndex.y) > uow * 0.22;

    let secondPosition = ankleSameLevelY && footAreCloseX && anklesFootCloseX && footIsAtDistanceY;

    if (secondPosition && finishDate === undefined) {
        finishDate = new Date();
        finishDate.setSeconds(finishDate.getSeconds() + timeToHoldFourStage);
    }
    if (hasFinishedTimeExpected()) {
        currentStage = 3;
        finishDate = undefined;
        hasShownInstruction = false;
        return;
    }

    const anklesAreCloseishX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, uow);
    const fingersAreCloseishX = mathUtils.arePointsClose(rFootIndex.x, lFootIndex.x, uow);
    const footIsAtSomeDistanceY = Math.abs(rFootIndex.y - lFootIndex.y) > uow * 0.13;

    if (finishDate === undefined) {
        return;
    }

    if ((!anklesAreCloseishX || !ankleSameLevelY || !fingersAreCloseishX)) {
        currentFailureWeight++;
    } else if (!footIsAtSomeDistanceY) {
        currentFailureWeight += 0.5;
    } else {
        currentFailureWeight = 0;
    }
}

function thirdPositionCalculation(keypoints) {
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];

    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
    const areAnklesSameLevelY = mathUtils.arePointsClose(rAnkle.y, lAnkle.y, (uow * 1.5));
    const anklesAreCloseX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.4));
    const kneesAreCloseX = mathUtils.arePointsClose(rKnee.x, lKnee.x, (uow));

    const thirdPosition = anklesAreCloseX && areAnklesSameLevelY && kneesAreCloseX;

    if (thirdPosition && finishDate === undefined) {
        finishDate = new Date();
        finishDate.setSeconds(finishDate.getSeconds() + timeToHoldFourStage);
    }
    if (hasFinishedTimeExpected()) {
        currentStage = 4;
        finishDate = undefined;
        hasShownInstruction = false;
        return;
    }    
    if (finishDate !== undefined && (!areAnklesSameLevelY || !kneesAreCloseX)) {
        currentFailureWeight++;
    } else {
        currentFailureWeight = 0;
    }
}

function fourthPositionCalculation(keypoints) {
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];

    const uow = (Math.abs(keypoints[params.KEYPOINTS.RIGHT_EAR].x - keypoints[params.KEYPOINTS.LEFT_EAR].x));
    const areAnklesSameLevelY = mathUtils.arePointsClose(rAnkle.y, lAnkle.y, uow);
    const anklesAreCloseX = mathUtils.arePointsClose(rAnkle.x, lAnkle.x, (uow * 0.5));

    const forthPosition = !anklesAreCloseX && !areAnklesSameLevelY;

    if (forthPosition && finishDate === undefined) {
        finishDate = new Date();
        finishDate.setSeconds(finishDate.getSeconds() + timeToHoldFourStage);
    }
    if (hasFinishedTimeExpected()) {
        finishDate = undefined;
        hasShownInstruction = false;
        setActivityMessage(params.EVENTS.FINISHED);
        assessmentPosition = params.ASSESSMENT_POSITION.COMPLETE;
        return;
    }    
    if (finishDate !== undefined && !forthPosition) {
        currentFailureWeight++;
    } else {
        currentFailureWeight = 0;
    }
}

module.exports = poseDetectionFS;
