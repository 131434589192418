import * as scatter from 'scatter-gl';
import * as params from '../../config/params';
import { Camera } from '../camera/camera.js';
import { file } from '@babel/types';

const activityPicker = require('../../core/mocap/activity_picker').default;

let activityName = '';
let activityDuration = '';

export class Video {
  constructor() {
    this.video = document.getElementById('video');
    this.canvas = document.getElementById('output');
    
    if (this.canvas) {
      this.ctx = this.canvas.getContext('2d');
    } else {
      console.error('Canvas not found');
      this.ctx = null;
    }

    this.scatterGLEl = document.querySelector('#scatter-gl-container');
    this.camera = new Camera();
    this.scatterGL = new scatter.ScatterGL(this.scatterGLEl, {
      'rotateOnStart': true,
      'selectEnabled': false,
      'styles': { polyline: { defaultOpacity: 1, deselectedOpacity: 1 } },
    });
    this.scatterGLHasInitialized = false;
  }

  static async setupVideo(file, testName, testDuration) {
    const video = new Video();
    video.video.src = file;
    activityName = testName;
    activityDuration = testDuration;
    const allowedOrigin = process.env.ALLOWED_ORIGIN;
    const videoUrl = new URL(file);

    if (allowedOrigin && videoUrl.origin !== allowedOrigin) {
      alert('Video source not allowed');
      throw new Error('Video source not allowed.');
    }

    video.video.setAttribute('crossorigin', 'anonymous');

    await new Promise((resolve) => {
      video.video.onloadedmetadata = () => {
        resolve(video);
      };
    });

    const videoWidth = video.video.videoWidth;
    const videoHeight = video.video.videoHeight;

    video.canvas.width = videoWidth;
    video.canvas.height = videoHeight;

    video.video.width = videoWidth;
    video.video.height = videoHeight;

    const canvasContainer = document.querySelector('.canvas-wrapper');
    canvasContainer.style = `width: ${videoWidth}px; height: ${videoHeight}px`;
    
    video.scatterGLEl.style =
    `width: ${videoWidth*2}px; height: ${videoHeight*2}px;`;
    video.scatterGL.resize();
    video.scatterGLEl.style.display =
      params.STATE.modelConfig.render3D ? 'inline-block' : 'none';

    video.video.load();
    video.video.play();

    return video;
  }

  renderFrame() {
    if (this.ctx) {
      this.drawCtx();
      requestAnimationFrame(this.renderFrame.bind(this));
    } else {
      console.error("Context is not available for rendering frames.");
    }
  }

  drawCtx() {
    if (this.ctx) {
      this.ctx.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    }
  }

  clearCtx() {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }

  drawResults(poses) {
    for (const pose of poses) {
      this.drawResult(pose);
    }
  }

  drawResult(pose) {
    if (pose.keypoints != null) {
      this.camera.drawKeypoints(pose.keypoints);
      this.camera.drawSkeleton(pose.keypoints, pose.id);
      activityPicker(pose.keypoints, activityName, activityDuration, 'video', this.canvas.height, this.canvas.width);
    }
    if (pose.keypoints3D != null && params.STATE.modelConfig.render3D) {
      this.camera.drawKeypoints3D(pose.keypoints3D);
    }
  }
}

export default Video;
