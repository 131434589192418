/* INFO: Points top left is 1000 x and 0 Y. The 0,0 is the top right. Camera is fliped. Btm is > 1000*/

import * as params from '../../../config/params';
import * as mathUtils from '../../../utils/math';
import * as puppet from '../puppet';
import {isMobile} from '../../../utils/device';
import * as messageService from '../../../services/message_service';
import { checkGeneralFall } from '../activity_picker';

const thresholdStartPoint = 0.75;
const valueNotDefined = -1;

let holdStartPositionCount = 0;
let userInStartPositionForTest = false;
let userHasWaitedForStart = false;
let holdIntructionCount = 0;
let assessmentPosition = params.ASSESSMENT_POSITION.NOT_STARTED;

let currentActivity = "";
let score = valueNotDefined;
let finishDate;
let timeLimitInSeconds = 30;

let desiredLeftAngle = valueNotDefined;
let desiredRightAngle = valueNotDefined;
let heightThreshold = valueNotDefined;
let frameHeight = valueNotDefined;
let frameWidth = valueNotDefined;
let inFrameWeight = valueNotDefined;

function setActivityMessage(event = "", currentScore = valueNotDefined){

    let secondsRemaining = calculateSecondsRemaining();

    if (currentScore > score) {
        score = currentScore;
    }

    const activityScore = messageService.ActivityScore(null, null, score, params.UNITS_SCORE.REPETITIONS);
    const msg = messageService.MessageBody(currentActivity, event, activityScore, secondsRemaining);

    messageService.sendActivityMessage(params.MESSAGE_TYPE.ACTIVITY, msg);
}

function calculateSecondsRemaining() {
    if (finishDate == undefined) {
        return null;
    } else {
        const currentDate = new Date();
        const timeDifference = (finishDate.getTime() - currentDate.getTime()) / 1000;
        return Math.ceil(timeDifference); 
    }
}

function hasFinishedTimeExpected() {
    const secondsRemaining = calculateSecondsRemaining();
    return (secondsRemaining < 0 && secondsRemaining != null);
}

function poseDetectionSTS(keypoints, activityName, activityDuration, source, videoHeight, videoWidth) {
    frameHeight = videoHeight;
    frameWidth = videoWidth;
    if (activityDuration === null || activityDuration == 0) {
        activityDuration = 30;
    }
    currentActivity = activityName;
    timeLimitInSeconds = activityDuration;
    
    switch (assessmentPosition) {
        case params.ASSESSMENT_POSITION.NOT_STARTED:
            holdStartPositionCount = 0;
            validateUserIsInFrame(keypoints);
            break;
        case params.ASSESSMENT_POSITION.SIT_TO_STAND:
            sitToStand(keypoints, source);
            break;
        case params.ASSESSMENT_POSITION.COMPLETE:
            score = valueNotDefined;
            userInStartPositionForTest = false;
            finishDate = undefined;
            assessmentPosition = params.ASSESSMENT_POSITION.END;
            break;
    }
}

function validateUserIsInFrame(keypoints) {
    const CLOSE_MARGIN_THRESHOLD = frameHeight * 0.03;

    const nose = keypoints[params.KEYPOINTS.NOSE];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const rHand = keypoints[params.KEYPOINTS.RIGHT_WRIST];
    const lHand = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];

    function validateBetweenLimits(keypoint) {
      let upperLimit = CLOSE_MARGIN_THRESHOLD;
      let bottomLimit = (frameHeight - CLOSE_MARGIN_THRESHOLD);
      let leftLimit = frameWidth * 0.05
      let rightLimit = frameWidth - leftLimit;
      let isBetweenY = keypoint.y > upperLimit && keypoint.y < bottomLimit;
      let isBetweenX = keypoint.x > leftLimit && keypoint.x < rightLimit;
      return isBetweenX && isBetweenY;
    }

    const arePointsInFrame = validateBetweenLimits(nose) && validateBetweenLimits(lAnkle) 
        && validateBetweenLimits(rAnkle) && validateBetweenLimits(rHand) 
        && validateBetweenLimits(lHand) && validateBetweenLimits(rHip) 
        && validateBetweenLimits(lHip);

    if (arePointsInFrame) {
        inFrameWeight++;
    } else {
        inFrameWeight = 0;
        setActivityMessage(params.EVENTS.NOT_IN_FRAME);
        assessmentPosition = params.ASSESSMENT_POSITION.NOT_STARTED;
    }

    const inFrameWeightThreshold = isMobile() ? 50 : 80;
    if (inFrameWeight > inFrameWeightThreshold) {
        setActivityMessage(params.EVENTS.IN_FRAME);
        assessmentPosition = params.ASSESSMENT_POSITION.SIT_TO_STAND;
    }
}

// -----------------------IN POSITION - START
function detectInPositionToStartTest(keypoints) {

    const nose = keypoints[params.KEYPOINTS.NOSE];
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    const leftAngle = Math.abs(180 - mathUtils.calculateAngle(lKnee, lHip, lAnkle, false));
    const rightAngle = Math.abs(180 - mathUtils.calculateAngle(rKnee, rHip, rAnkle, true));

    // function checkArmsCloseness() {
    //     const areWristCloseToShoulders = mathUtils.arePointsClose(rWrist.x, rShoulder.x, uow*3) && mathUtils.arePointsClose(lWrist.x, lShoulder.x, uow*3)
    //     return areWristCloseToShoulders
    // }

    validateUserIsInFrame(keypoints);

    // if (checkArmsCloseness()) {
        let isSittedToTheRight = puppet.isFacingRight(nose, lShoulder, rShoulder) && puppet.leftIsBent(leftAngle)
        let isSittedToTheLeft = puppet.isFacingLeft(nose, lShoulder, rShoulder) && puppet.rightIsBent(rightAngle)
        if (isSittedToTheRight || isSittedToTheLeft) {
            holdStartPositionCount++;
        } else {
            holdStartPositionCount = 0;
        }
    // }

    const timeToHold = isMobile() ? 70 : 100;
    let assessmentStartCondition = holdStartPositionCount > timeToHold;
    if (assessmentStartCondition) {
        holdStartPositionCount = 0;
        userInStartPositionForTest = true;
        setActivityMessage(params.EVENTS.IN_POSITION);
    }
}

function waitInPosition(keypoints) {
    const timeExpectedUntilStart = 5;
   
    if (finishDate === undefined) {
        
        const lWristY = keypoints[params.KEYPOINTS.LEFT_WRIST].y;
        const rWristY = keypoints[params.KEYPOINTS.RIGHT_WRIST].y;
        const head = keypoints[params.KEYPOINTS.NOSE];
        const hasAtLeastOneArmRaised = lWristY <= head.y || rWristY <= head.y;
        if (hasAtLeastOneArmRaised) {
            finishDate = new Date();
            finishDate.setSeconds(finishDate.getSeconds() + timeExpectedUntilStart);
        }
    }  
    
    if (hasFinishedTimeExpected()) {
        const nose = keypoints[params.KEYPOINTS.NOSE];
        const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
        const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
        const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
        const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];
        const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
        const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
        const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
        const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

        desiredLeftAngle = Math.abs(180 - mathUtils.calculateAngle(lKnee, lHip, lAnkle, false));
        desiredRightAngle = Math.abs(180 - mathUtils.calculateAngle(rKnee, rHip, rAnkle, true));
        const bestShoulder = puppet.isFacingRight(nose, lShoulder, rShoulder) ? lShoulder : rShoulder;
        const bestHip = puppet.isFacingRight(nose, lShoulder, rShoulder) ? lHip : rHip;
        heightThreshold = (bestShoulder.y + bestHip.y) / 2;
        finishDate = undefined;
        setActivityMessage(params.EVENTS.STARTED, 0); // Set initial score to 0.
        userHasWaitedForStart = true;
    } else {
        setActivityMessage(params.EVENTS.IN_POSITION);
        holdIntructionCount++;
    }
}

// ----------------------- SIT TO STAND - START
let repsLocked = false;

function sitToStand(keypoints, source) {

    //VALUES
    const nose = keypoints[params.KEYPOINTS.NOSE];
    const lHip = keypoints[params.KEYPOINTS.LEFT_HIP];
    const rHip = keypoints[params.KEYPOINTS.RIGHT_HIP];
    const lWrist = keypoints[params.KEYPOINTS.LEFT_WRIST];
    const rWrist = keypoints[params.KEYPOINTS.RIGHT_WRIST];
    const lKnee = keypoints[params.KEYPOINTS.LEFT_KNEE];
    const rKnee = keypoints[params.KEYPOINTS.RIGHT_KNEE];
    const lAnkle = keypoints[params.KEYPOINTS.LEFT_ANKLE];
    const rAnkle = keypoints[params.KEYPOINTS.RIGHT_ANKLE];
    const lShoulder = keypoints[params.KEYPOINTS.LEFT_SHOULDER];
    const rShoulder = keypoints[params.KEYPOINTS.RIGHT_SHOULDER];

    const leftAngle = Math.abs(180 - mathUtils.calculateAngle(lKnee, lHip, lAnkle, false));
    const rightAngle = Math.abs(180 - mathUtils.calculateAngle(rKnee, rHip, rAnkle, true));

    function isFacingRight() {
        return puppet.isFacingRight(nose, lShoulder, rShoulder);
    }

    function isFacingLeft() {
        return puppet.isFacingLeft(nose, lShoulder, rShoulder)
    }

    function personIsSitted() {
        if (isFacingRight()) {
            return mathUtils.angleIsNearTo(leftAngle, desiredLeftAngle, 10);
        } else if (isFacingLeft()) {
            return mathUtils.angleIsNearTo(rightAngle, desiredRightAngle, 10);
        } else {
            return false;
        }
    }

    function personIsStanding() {
        if (isFacingRight()) {
            return puppet.leftIsStraight(leftAngle);
        } else if (isFacingLeft()) {
            return puppet.rightIsStraight(rightAngle);
        } else {
            return false;
        }
    }

    function isDoingTest() {
        const isFrontal = puppet.isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint);
        let ankleBetweenHipAndKnee = false;
        if (isFacingRight()) {
            ankleBetweenHipAndKnee = puppet.bodyPartIsBetweenX(lAnkle, lHip, lKnee);
        }
        if (isFacingLeft()) {
            ankleBetweenHipAndKnee = puppet.bodyPartIsBetweenX(rAnkle, rHip, rKnee);
        }
        return !isFrontal && ankleBetweenHipAndKnee;
    }

    // Initial check to see if user is in position to start test, videos are not included in this check
    if (!userInStartPositionForTest && source !== 'video') {
        detectInPositionToStartTest(keypoints);
        return;
    }

    if (!userHasWaitedForStart) {
        waitInPosition(keypoints);
        return;
    }
    
    if (!personIsSitted()) {
        // TODO: once we merge branch 32, this validation goes to camera.
        if (checkGeneralFall(keypoints, heightThreshold)) {
            finishDate = undefined;
            setActivityMessage(params.EVENTS.FALL, score);
            assessmentPosition = params.ASSESSMENT_POSITION.END;
            return;
        }
    }

    // Validate if user is still in frame
    // (Consider 'notInPosition' func in iOS app)
    // If not --> define what to do (restart, abort, finish...)

    if (finishDate == undefined) {
        finishDate = new Date();
        finishDate.setSeconds(finishDate.getSeconds() + timeLimitInSeconds);
    }
   
    if (hasFinishedTimeExpected()) {
        finishDate = undefined;
        setActivityMessage(params.EVENTS.FINISHED, score);
        assessmentPosition = params.ASSESSMENT_POSITION.COMPLETE;
        return;
    }

    // if (isDoingTest()) {
        if (repsLocked) {
            if (personIsSitted()) {
                repsLocked = false;
            }
        } else {
            if (personIsStanding()) {
                score ++;
                repsLocked = true;
            }
        }
    // }
    setActivityMessage(params.EVENTS.STARTED, score);
}

module.exports = poseDetectionSTS;
