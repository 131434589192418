import * as mathUtils from '../../utils/math';

export function areStraight(leftAngle, rightAngle, tolerance = 20) {
    return leftIsStraight(leftAngle, tolerance) && rightIsStraight(rightAngle, tolerance);
}

export function leftIsStraight(leftAngle, tolerance = 30) {
    return mathUtils.angleIsHorizontal(leftAngle, tolerance);
}

export function rightIsStraight(rightAngle, tolerance = 30) {
    return mathUtils.angleIsHorizontal(rightAngle, tolerance);
}

export function areBent(leftAngle, rightAngle, tolerance = 20) {
    return leftIsBent(leftAngle, tolerance) && rightIsBent(rightAngle, tolerance);
}

export function leftIsBent(leftAngle, tolerance = 30) {
    return mathUtils.angleIsVertical(leftAngle, tolerance);
}

export function rightIsBent(rightAngle, tolerance = 30) {
    return mathUtils.angleIsVertical(rightAngle, tolerance);
}

export function bodyPartIsHigher(p1, p2, uow, multiplier = 1) {
    return p1.y < p2.y && mathUtils.dist(p1.x, p1.y, p2.x, p2.y) > uow * multiplier;
}

export function isTorsoFrontal(leftHip, rightHip, leftShoulder, rightShoulder) {
    //The calculations are equal to apps but is not precise, use byScoring if possible
    const middlePointHip = {
        x: (leftHip.x + rightHip.x) / 2,
        y: (leftHip.y + rightHip.y) / 2
    };
    const middlePointShoulder = {
        x: (leftShoulder.x + rightShoulder.x) / 2,
        y: (leftShoulder.y + rightShoulder.y) / 2
    };

    const middlePoint = {
        x: (middlePointHip.x + middlePointShoulder.x) / 2,
        y: (middlePointHip.y + middlePointShoulder.y) / 2
    };

    const vectorHip = {
        x: leftHip.x - middlePoint.x,
        y: leftHip.y - middlePoint.y
    };
    const vectorShoulder = {
        x: leftShoulder.x - middlePoint.x,
        y: leftShoulder.y - middlePoint.y
    };

    let result = ((vectorHip.x * vectorShoulder.x) + (vectorHip.y * vectorShoulder.y)) / (Math.sqrt((vectorHip.x * vectorHip.x) + (vectorHip.y * vectorHip.y)) * Math.sqrt((vectorShoulder.x * vectorShoulder.x) + (vectorShoulder.y * vectorShoulder.y)));
    if (result < -1) { return false; }

    result = Math.round(result * 100) / 100;
    result = Math.acos(result);
    result = (result * 180) / Math.PI;

    if (isNaN(result)) { return false; }

    return mathUtils.angleIsNearTo(result, 90, 20);
}

export function isFacingLeft(nose, lShoulder, rShoulder) {
    const middlePointShoulder = (lShoulder.x + rShoulder.x) / 2;
    return nose.x > middlePointShoulder;
}

export function isFacingRight(nose, lShoulder, rShoulder) {
    const middlePointShoulder = (lShoulder.x + rShoulder.x) / 2;
    return nose.x < middlePointShoulder;
}

export function isTorsoFrontalByScoring(keypoints, params, thresholdStartPoint) {
    const lAnkleScore = keypoints[params.KEYPOINTS.LEFT_ANKLE].score;
    const rAnkleScore = keypoints[params.KEYPOINTS.RIGHT_ANKLE].score;
    const lShoulderScore = keypoints[params.KEYPOINTS.LEFT_SHOULDER].score;
    const rShoulderScore = keypoints[params.KEYPOINTS.RIGHT_SHOULDER].score;
    const lWristScore = keypoints[params.KEYPOINTS.LEFT_WRIST].score;
    const rWristScore = keypoints[params.KEYPOINTS.RIGHT_WRIST].score;
    const lHipScore = keypoints[params.KEYPOINTS.LEFT_HIP].score;
    const rHipScore = keypoints[params.KEYPOINTS.RIGHT_HIP].score;

    const lEarScore = keypoints[params.KEYPOINTS.LEFT_EAR].score;
    const rEarScore = keypoints[params.KEYPOINTS.RIGHT_EAR].score;

    const lElbowScore = keypoints[params.KEYPOINTS.LEFT_ELBOW].score;
    const rElbowScore = keypoints[params.KEYPOINTS.RIGHT_ELBOW].score;

    const averageScoreR = (rAnkleScore + rShoulderScore + rWristScore + rHipScore + rEarScore + rElbowScore) / 6;
    const averageScoreL = (lAnkleScore + lShoulderScore + lWristScore + lHipScore + lEarScore + lElbowScore) / 6;

    return averageScoreR > thresholdStartPoint && averageScoreL > thresholdStartPoint;
}

export function bodyPartIsBetweenX(centerPoint, point1, point2) {
    return (centerPoint.x > point1.x && centerPoint.x < point2.x) || (centerPoint.x > point2.x && centerPoint.x < point1.x);
}

export function bodyPartIsBetweenY(centerPoint, point1, point2) {
    return (centerPoint.y > point1.y && centerPoint.y < point2.y) || (centerPoint.y > point2.y && centerPoint.y < point1.y);
} 

// Calculate 
export function getMidPoint(p1, p2) {
    const midX = (p1.x + p2.x) / 2;
    const midY = (p1.y + p2.y) / 2;
    return { x: midX, y: midY };
}
  
// Calculate (CHEST) point between Sholuders 
export function getChestPoint(leftShoulder, rightShoulder) {
    return getMidPoint(leftShoulder, rightShoulder);
  }
  
// Connect (CHEST) point with (NOSE) point 
export function connectChestToNose(chestPoint, nosePoint, ctx) {
    ctx.beginPath();
    ctx.moveTo(chestPoint.x, chestPoint.y);
    ctx.lineTo(nosePoint.x, nosePoint.y);
    ctx.stroke();
}