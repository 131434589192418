import fourStageAnalyzer from './activities/four_stage';
import sitToStandAnalyzer from './activities/sit_to_stand';
import * as params from '../../config/params';

function activityPicker(keypoints, activityName, activityDuration, source, videoHeight, videoWidth) {
  if (typeof activityName !== 'string') {
    return;
  }
  activityName = activityName.toUpperCase();

  activityDuration = parseInt(activityDuration, 10);
  if (isNaN(activityDuration)) {
    activityDuration = null;
  }

  switch (activityName) {
    case "SITTOSTAND":
      sitToStandAnalyzer(keypoints, activityName, activityDuration, source, videoHeight, videoWidth);
      break;
    case "FOURSTAGEBALANCE":
      fourStageAnalyzer(keypoints, activityName, activityDuration, source, videoHeight, videoWidth);
      break;
    default:
      console.error("No matching activity");
      break;
  }
}

export default activityPicker;

// Checks if the percentage of upper body keypoints located below the given height threshold is above 50%
export function checkGeneralFall(keypoints, heightThreshold) {
  let hasFallen = false;
  let keypointsBelowMiddle = 0;
  let percentageThreshold = 0.6

  const keypointsIndexes = [
    params.KEYPOINTS.NOSE,
    params.KEYPOINTS.CHEST,
    params.KEYPOINTS.LEFT_SHOULDER, params.KEYPOINTS.RIGHT_SHOULDER,
    params.KEYPOINTS.LEFT_ELBOW, params.KEYPOINTS.RIGHT_ELBOW,
    params.KEYPOINTS.LEFT_WRIST, params.KEYPOINTS.RIGHT_WRIST,
  ];

  const filteredKeypoints = keypoints.filter((_, index) => keypointsIndexes.includes(index));
  for (const keypoint of filteredKeypoints) {
    if (keypoint.y > heightThreshold) {
      keypointsBelowMiddle++;
    }
  }
  if (keypointsBelowMiddle/filteredKeypoints.length > percentageThreshold) {
    hasFallen = true;
  }

  return hasFallen;
}
