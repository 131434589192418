// Copyright 2023 The MediaPipe Authors.
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { PoseLandmarker, FilesetResolver } from '@mediapipe/tasks-vision';
import * as mathUtils from '../../utils/math';
const runningMode = "VIDEO";
const decimalPlaces = 3;
let didSetEstimationOptions = false;
let lastVideoTime = -1;
let previousLandmarks = null;
let prePreviousLandmarks = null;
export class MediaPipeModel {
    constructor() {
        this.poseLandmarker = undefined;
        this.mappedKeypoints = [];
    }
    async createPoseLandmarker() {
        const vision = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm");
        this.poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
            baseOptions: {
                modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_heavy/float16/1/pose_landmarker_heavy.task`,
                delegate: "GPU"
            },
            runningMode: runningMode,
            numPoses: 1,
        });
    };
    async configureStream() {
        if (!didSetEstimationOptions) {
            didSetEstimationOptions = true;
            await this.poseLandmarker.setOptions({ runningMode: runningMode });
        }
    }
    async setupModel() {
        await this.createPoseLandmarker();
        await this.configureStream();
    }
    async predict(video) {
        let startTimeMs = performance.now();
        if (lastVideoTime !== video.currentTime) {
            lastVideoTime = video.currentTime;
            this.poseLandmarker.detectForVideo(video, startTimeMs, async (result) => {
                this.mappedKeypoints = await this.mapKeypoints(result.landmarks[0], video);
            });
        }
    }
    async mapKeypoints(landmarks, video) {
        
        let mappedKeypoints = [];
        let smoothedLandmarks = [];
        if (!landmarks) { return [] };   
        if (prePreviousLandmarks == null) {prePreviousLandmarks = landmarks; return []; };
        if (previousLandmarks == null) {previousLandmarks = landmarks; return []; };
        for (let i = 0; i < landmarks.length; i++){   
            let smoothedLandmark = this.smoothLandmark(landmarks[i], previousLandmarks[i], prePreviousLandmarks[i]);       
            smoothedLandmarks.push(smoothedLandmark);
            let covertedLandmark = {
                score: landmarks[i].visibility,
                x: smoothedLandmark.x * video.width,
                y: smoothedLandmark.y * video.height,
                z: smoothedLandmark.z
            }
            mappedKeypoints.push(covertedLandmark);
        }
        prePreviousLandmarks = previousLandmarks;
        previousLandmarks = smoothedLandmarks;
        
        let poses = [{
            keypoints: mappedKeypoints
        }];
        return poses;
    }
    
    smoothLandmark(landmark, previousLandmarks, prePreviousLandmarks, smoothingFactors = [0.4, 0.4, 0.2]) {
        //The smoothing factors are the % of weight assigned to current frame, previous frame and frame from two frames ago.
        //As such, they must add to 1.
        if (smoothingFactors.reduce((sum, weight) => sum + weight, 0) != 1){
            console.error("Smooth factors must add up to 1");
        }
        return {
            x: mathUtils.round(smoothingFactors[0] * landmark.x +
                smoothingFactors[1] * previousLandmarks.x +
                smoothingFactors[2] * prePreviousLandmarks.x, decimalPlaces),
            y: mathUtils.round((smoothingFactors[0] * landmark.y +
                smoothingFactors[1] * previousLandmarks.y +
                smoothingFactors[2] * prePreviousLandmarks.y), decimalPlaces),
            z: mathUtils.round((smoothingFactors[0] * landmark.z +
                smoothingFactors[1] * previousLandmarks.z +
                smoothingFactors[2] * prePreviousLandmarks.z), decimalPlaces)
        };        
     }
}